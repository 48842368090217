import React, { useState, useEffect, Fragment } from 'react';
import './App.css';
import Routes from "./routes";
import { langObj } from "./locale/index";
import { LangCtx, PayLoadCtx, RoleCtx, SignalerCtx, CustomCtx, SpecializationsCtx, DivisionCtx, DistrictCtx, QualificationCtx } from "./lib/context";
// import { Auth } from "aws-amplify";
import Loader from "./lib/ui/loader/Loader";
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import { createTheme } from '@material-ui/core/styles';
import { ToastContainer, toast } from 'react-toastify';
import ConsultSignaler from "./lib/consultSignaler";
import 'react-toastify/dist/ReactToastify.css';
import config from "./config.json";
import { currentsession, currentuserinfo, getSpecializationList, getDomainData, getDivisionList, getDistrictList, getQualificationList } from './actions/users'
import { Auth, API } from "aws-amplify";
import SessionExpired from "./containers/common/SessionExpired";
import ConnectionLost from "./containers/common/ConnectionLost";
import Amplify from "aws-amplify";

// import ServerDisconnected from "./containers/common/ServerDisconnected";

const { parseDomain } = require("parse-domain");

const theme = createTheme({
  palette: {
    primary: { main: '#1c9f67', },
    secondary: {
      main: '#2b80c4',
    }
  },

});

// if (window.location.hostname !== 'localhost') {
//   console.log = function(){};
//   window.console.log = function() {};
// }

function App() {
  const [l, setLang] = useState(langObj[config.defaultLang]);
  const [payload, setPayload] = useState(null);
  const [role, setRole] = useState(null);
  const [loading, setLoading] = useState(true);
  const [signaler, setSignaler] = useState(null);
  const [divisionList, setDivisionList] = useState(null);
  const [districts, setDistricts] = useState(null);
  const [custom, setCustom] = useState({ check: false, logo: config.logo, whiteLable: false, subDomain: config.defaultdomian, defaultClinicId: config.defaultClinicId, clientstripekey: config.clientstripekey, serverstripekey: config.serverstripekey, name: config.defaultClinicName, poolId: config.cognito.USER_POOL_ID, identityId: config.cognito.IDENTITY_POOL_ID })
  const [socket_status, setSocketStatus] = useState("INIT");
  const [internet_status, setNetStatus] = useState(window.navigator.onLine);
  // const [specializationsList, setSpecializationsList] = useState(null);
  // const [initialNetwork, setInitialNetwork] = useState('');
  const [qualifications, setQualifications] = useState(null);
  const [specializations, setSpecializations] = useState(null);

  const _domain = parseDomain(window.location.hostname);
  const [networkSpeed, setNetworkSpeed] = useState(navigator?.connection?.effectiveType);
  const [speedMbps, setSpeedMbps] = useState(null);
  const [lastSpeedMbps, setLastSpeedMbps] = useState(null);

  const awsconfig = (domainConfig) => {
    Amplify.configure({
      Auth: {
        mandatorySignIn: true,
        region: config.cognito.REGION,
        userPoolId: domainConfig && domainConfig.poolId ? domainConfig.poolId : config.cognito.USER_POOL_ID,
        identityPoolId: domainConfig && domainConfig.identityId ? domainConfig.identityId : config.cognito.IDENTITY_POOL_ID,
        userPoolWebClientId: domainConfig && domainConfig.appClientId ? domainConfig.appClientId : config.cognito.APP_CLIENT_ID
      },
      Storage: {
        region: config.s3.REGION,
        bucket: config.s3.BUCKET,
        identityPoolId: domainConfig && domainConfig.identityId ? domainConfig.identityId : config.cognito.IDENTITY_POOL_ID
      },
      API: {
        endpoints: [
          {
            name: "schedule",
            endpoint: config.schGateway.URL,
            region: config.schGateway.REGION
          },
          {
            name: "users",
            endpoint: config.usersGateway.URL,
            region: config.usersGateway.REGION
          },
          {
            name: "appointment",
            endpoint: config.appointGateway.URL,
            region: config.appointGateway.REGION
          },
          {
            name: "admin",
            endpoint: config.adminGateway.URL,
            region: config.adminGateway.REGION
          },

          {
            name: "mcu",
            endpoint: config.mcuGateway.URL,
            region: config.mcuGateway.REGION
          }
        ]
      }
    })
    setCustom({
      check: true,
      logo: domainConfig?.logo ? domainConfig.logo : config.logo,
      whiteLable: true,
      subDomain: domainConfig?.subdomain ? domainConfig.subdomain : config.defaultdomian,
      defaultClinicId: domainConfig ? domainConfig.clinicId ? domainConfig.clinicId : null : config.defaultClinicId,
      name: domainConfig?.name ? domainConfig.name : config.defaultClinicName,
      poolId: domainConfig?.poolId ? domainConfig.poolId : config.cognito.USER_POOL_ID,
      identityId: domainConfig?.identityId ? domainConfig.identityId : config.cognito.IDENTITY_POOL_ID
      // clientstripekey: res?.clientstripekey ? res.clientstripekey : config.clientstripekey, 
      // serverstripekey: res?.serverstripekey ? res.serverstripekey : config.serverstripekey 
    })
  }

  // useEffect(() => {
  //   if (speedMbps !== null) {
  //     if (speedMbps < 50) {
  //       toast.error('Poor Connection. Check connectivity', { autoClose: 3000 });
  //     } else {
  //       toast.success('Your network is back', { autoClose: 3000 });
  //     }
  //   }
  // }, [speedMbps]);

  // const testNetworkSpeed = async () => {
  //   // const imageUrl = 'https://www.pexels.com/video/a-boy-wears-a-virtual-reality-headset-3209828/'; // URL to the small file
  //   const imageUrl = 'https://images.pexels.com/photos/1108099/pexels-photo-1108099.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2'; // URL to the small file
  //   const startTime = new Date().getTime();

  //   try {
  //     const response = await fetch(imageUrl);
  //     // if (!response.ok) throw new Error('Network response was not ok');

  //     const blob =await response.blob();
  //     const fileSize = blob.size;
  //     const endTime = new Date().getTime();
  //     const duration = (endTime - startTime) / 1000; // Time in seconds
  //     console.log('duration----',duration)

  //     console.log('fileSize,,,,',fileSize)

  //     if (fileSize) {
  //       const speedBps = fileSize / duration; // Speed in bytes per second
  //       const speedKbps = (speedBps * 8) / 1024; // Convert to kbps
  //       const speedMbps = (speedKbps/1000); //convert to Mbps
  //       console.log('speed in mbps--',speedMbps.toFixed(2))
  //       // setSpeedMbps(speedMbps);

  //       if (speedMbps < 50) {
  //         toast.error('Poor Connection. Check connectivity');
  //       }
  //       //  else {
  //       //   toast.success('Your network is back', { autoClose: 3000 });
  //       // }
        
  //     } else {
  //       // setSpeedMbps('Could not determine file size');
  //       toast.error('Could not determine file size');
  //     }
  //   } catch (error) {
  //     // setSpeedMbps('Error fetching the file');
  //     toast.error('Error fetching the file');
  //     console.error('Error fetching the file:', error);
  //   }
  // };


  // useEffect(() => {
  //   const updateNetworkInfo = () => {
  //     if (navigator.onLine) {
  //       console.log("network changes, ", navigator.connection.effectiveType);
  //       // setNetworkSpeed(navigator.connection.effectiveType);
  //       testNetworkSpeed();
  //     }
  //   };

  //   navigator.connection.addEventListener('change', updateNetworkInfo);

  //   return () => {
  //     navigator.connection.removeEventListener('change', updateNetworkInfo);
  //   };
  // }, []);

  // useEffect(() => {
  //   setLoading(false); // Set loading to false once everything is loaded
  // }, []);


  useEffect(() => {
    let lang = localStorage.getItem('lang');
    if (lang && lang !== config.defaultLang) {
      let l_data = langObj[lang] ? langObj[lang] : langObj[config.defaultLang];
      setLang(l_data);
    }
    setLoading(true);
    if (config.isOffline) {
      currentsession().then(res => {
        const userGroups = res && res.accessToken && res.accessToken.payload ? res.accessToken.payload['groups'] : [];
        console.log("userGroups ==== ", userGroups);
        let _role = userGroups.findIndex(group => group === 'Doctor') >= 0 ? "D" : userGroups.findIndex(group => group === 'Patient') >= 0 ? "P" : userGroups.findIndex(group => group === 'Clinic') >= 0 ? "C" : userGroups.findIndex(group => group === 'ClinicAdmin' || 'SuperAdmin') >= 0 ? "CA" : null;
        console.log("_role === ", _role);
        setRole(_role || null);
        getPayLoad();
      }).catch(err => {
        console.log("err === ", err);
        setLoading(false);
      })
    } else {
      console.log("_domain ======== ", _domain);
      if (window.location.hostname !== 'localhost' && _domain && _domain.subDomains[0] !== config.defaultdomian) {
        getDomainData(_domain.subDomains[0]).then(res => {
          console.log("res in getDomainData ==== ", res)
          awsconfig(res)
        }).catch(err => {
          awsconfig(null);
        })

        // API.get("users", `/domainlist/${_domain.subDomains[0]}`)
        //   .then(res => {
        //     setCustom({
        //       check: true,
        //       logo: res?.logo ? res.logo : config.logo,
        //       whiteLable: true,
        //       subDomain: res?.subdomain ? res.subdomain : config.defaultdomian,
        //       defaultClinicId: res?.clinicId ? res.clinicId : null,
        //       name: res.name ? res.name : config.defaultClinicName
        //       // clientstripekey: res?.clientstripekey ? res.clientstripekey : config.clientstripekey, 
        //       // serverstripekey: res?.serverstripekey ? res.serverstripekey : config.serverstripekey 
        //     })
        //   })
        //   .catch(err => {
        //     console.log("err====", err);
        //     // window.location.href = 'https://inclinix.instavc.com/';
        //     // return;
        //     setCustom({
        //       check: true, logo: config.logo, subDomain: config.defaultdomian, defaultClinicId: config.defaultClinicId, name: config.defaultClinicName
        //       // clientstripekey: config.clientstripekey, serverstripekey: config.serverstripekey 
        //     })
        //   });
      } else {
        awsconfig(null);
        // setCustom({
        //   check: true, logo: config.logo, subDomain: config.defaultdomian, defaultClinicId: config.defaultClinicId, name: config.defaultClinicName
        //   // clientstripekey: config.clientstripekey, serverstripekey: config.serverstripekey 
        // })
      }
    }
    window.addEventListener('offline', (event) => {
      if (!window.location.href.includes('waiting-room')) {
        setNetStatus(false);
      }
    });
    window.addEventListener('online', (event) => {
      setNetStatus(true);
      if (!window.location.href.includes('waiting-room')) {
        window.location.reload();
      }
    });
  }, []);

  useEffect(() => {
    getDropdownData()
    if (custom?.check) {
      Auth.currentSession().then(mySession => {
        const userGroups = mySession && mySession.accessToken && mySession.accessToken.payload ? mySession.accessToken.payload['cognito:groups'] : [];
        // console.log("userGroups ==== ", mySession.accessToken.payload);
        let _role = userGroups.findIndex(group => group === 'Doctor') >= 0 ? "D" : userGroups.findIndex(group => group === 'Patient') >= 0 ? "P" : userGroups.findIndex(group => group === 'Clinic') >= 0 ? "C" : userGroups.findIndex(group => group === 'ClinicAdmin') >= 0 ? "CA" : userGroups.findIndex(group => group === 'Admin') >= 0 ? "SA" : null;
        setRole(_role || null);
        getPayLoad();
      }).catch(err => {
        console.log("err === ", err);
        setLoading(false);
      })
    }
  }, [custom]);

  useEffect(() => {
    let ele = document.getElementsByTagName("body");
    if (l && l.locale === "ar") {
      ele[0].classList.add('rtl');
    } else {
      ele[0].classList.remove('rtl');
    }

  }, [l]);

  useEffect(() => {
    if (payload && payload.userId && role) {
      // if (role !== "SA" && role !== "CA") {
      if (role !== "SA") {
        if (signaler === null) {
          const consultSignaler = new ConsultSignaler();
          consultSignaler.init(payload.userId, role);
          setSignaler(consultSignaler);
        }
      } else {
        setSignaler(null);
        setSocketStatus("CONN");
      }
    } else {
      if (signaler) {
        signaler.closeSocket();
        setSignaler(null);
      }
    }
    return () => {
      if (signaler && payload === null && role === null) {
        signaler.closeSocket();
        setSignaler(null);
      }

    }
  }, [payload, role]);

  useEffect(() => {
    if (signaler && signaler !== null) {
      signaler.on(`disconnected`, () => {
        setSocketStatus("DISCONN")
      });
      signaler.on(`connected`, () => {
        setSocketStatus("CONN")
      });
      return () => {
        signaler.off(`connected`, () => { });
        signaler.off(`disconnected`, () => { });
      };
    }
  }, [signaler]);


  const getPayLoad = async () => {
    // const payLoad = await Auth.currentUserInfo();
    if (config.isOffline) {
      await currentuserinfo().then(res => {
        // console.log("res in user info ==== ", res);
        if (res && res.attributes && res.attributes.userId) {
          setPayload(res && res.attributes ? res.attributes : null);
        }
        setLoading(false);

      }).catch(err => {
        console.log("err in current userinfo======", err);
        setLoading(false);
        setPayload(null);
      })
    } else {
      const userInfo = await Auth.currentUserInfo();
      if (userInfo && userInfo.attributes && userInfo.attributes.sub) {
        await currentuserinfo(userInfo.attributes.sub).then(res => {
          setPayload(res && res.attributes && res.attributes.userId ? res.attributes : null);
          setLoading(false);
        }).catch(err => {
          console.log("err in current userinfo======", err);
          setPayload(null);
          setLoading(false);
        })
      } else {
        setPayload(null);
        setLoading(false);
      }
    }
  }



  const getDropdownData = () => {
    // getDepartmentList().then(res => {
    //   if (res && res.data) {
    //     setDeparments([...res.data])
    //   }

    // }).catch(err => {
    //   console.log("err in get specialization======", err);
    // })


    getDivisionList().then(res => {
      if (res && res.data) {
        // console.log('cities---', res.data)
        setDivisionList([...res.data])
      }
    }).catch(err => {
      console.log("err in get divisions======", err);
    })

    getDistrictList().then(res => {
      if (res && res.data) {
        // console.log('cities---', res.data)
        setDistricts([...res.data])
      }
    }).catch(err => {
      console.log("err in get districts======", err);
    })

    getSpecializationList().then(res => {
      if (res && res.data) {
        setSpecializations([...res.data])
      }

    }).catch(err => {
      console.log("err in get specialization======", err);
    })

    getQualificationList().then(res => {
      if (res && res.data) {
        setQualifications([...res.data])
      }

    }).catch(err => {
      console.log("err in get qualifications ======", err);
    })

  }


  return (
    <MuiThemeProvider theme={theme}>
      <LangCtx.Provider value={{ l, setLang }}>
        <PayLoadCtx.Provider value={{ payload, getPayLoad, setPayload }}>
          <RoleCtx.Provider value={{ role, setRole }}>
            <SignalerCtx.Provider value={{ signaler }}>
              <CustomCtx.Provider value={{ custom, setCustom }}>
                <DivisionCtx.Provider value={{ divisionList }}>
                  <DistrictCtx.Provider value={{ districts }}>
                    <SpecializationsCtx.Provider value={{ specializations }}>
                      <QualificationCtx.Provider value={{ qualifications }}>
                        <div className="App">
                          {socket_status === "DISCONN" && <SessionExpired />}
                          {!internet_status && <ConnectionLost />}
                          {/* {initialNetwork && <ToastContainer/>} */}
                          {loading ? <Loader /> : <Fragment>
                            {socket_status === "INIT" && payload !== null && <Loader />}

                          </Fragment>}
                          <Routes />
                          <ToastContainer />
                        </div>
                      </QualificationCtx.Provider>
                    </SpecializationsCtx.Provider>
                  </DistrictCtx.Provider>
                </DivisionCtx.Provider>
              </CustomCtx.Provider>
            </SignalerCtx.Provider>
          </RoleCtx.Provider>
        </PayLoadCtx.Provider>
      </LangCtx.Provider>
    </MuiThemeProvider>
  );
}

export default App;