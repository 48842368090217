import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import Home from "../layouts/home/index";
import { PayLoadCtx, RoleCtx } from "../lib/context";

const PublicRoute = ({ component: C, props: cProps, ...rest }) => {
    const { payload } = useContext(PayLoadCtx);
    const { role } = useContext(RoleCtx);
    return <Route
        {...rest}
        render={props => payload ?
            (role === "D" ? <Redirect to="/doctor-appointments" />
                : role === "P" ? <Redirect to="/patient-dashboard" />
                    : role === "C" ? <Redirect to="/clinic-dashboard" />
                        : role === "CA" ? <Redirect to="/admin-dashboard" />
                            : role === "SA" ? <Redirect to="/dashboard" />
                                : <Home {...props} {...cProps} component={(props) => <C  {...cProps} {...props} />} />)
            : <Home {...props} {...cProps} component={(props) => <C  {...cProps} {...props} />} />} />
}

export default PublicRoute;
