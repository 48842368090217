import React, { useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { LangCtx } from "../../../lib/context";

// import { Link, useLocation, useHistory } from 'react-router-dom';
// import { Auth } from "aws-amplify";
// import { LangCtx } from "../../../lib/context";

const ClinicSideNavLinks = (props) => {
    const location = useLocation();
    const { l } = useContext(LangCtx);

    return (
        <>
            <ul className="sdnvLinkGroup">
                {/* clinic links */}
                <li className="sdnvLinkItem">
                    <Link to="/clinic-dashboard" onClick={props.hideMenu} className={location.pathname === "/clinic-dashboard" ? "sdnvLink activeLink" : "sdnvLink"}>
                        <img src="/images/icons/dashboard.png" className="sdnvLinkImg invertGray" alt="icon" />
                        <div className="sdnvLinkTxt"><span>{l.dashboard}</span></div>
                    </Link>
                </li>
                <li className="sdnvLinkItem">
                    <Link to="/clinic-createusers" onClick={props.hideMenu} className={location.pathname === "/clinic-createusers" ? "sdnvLink activeLink" : "sdnvLink"}>
                        <img src="/images/icons/create-patient.png" className="sdnvLinkImg invertGray" alt="icon" />
                        <div className="sdnvLinkTxt"><span>{l.create_user}</span></div>
                    </Link>
                </li>
                {/* <li className="sdnvLinkItem">
                    <Link to="/create-patient" onClick={props.hideMenu} className={location.pathname === "/create-patient" ? "sdnvLink activeLink" : "sdnvLink"}>
                        <img src="/images/icons/create-patient.png" className="sdnvLinkImg invertGray" alt="icon" />
                        <div className="sdnvLinkTxt"><span>{l.cret_new_pat}</span></div>
                    </Link>
                </li> */}
                {/* <li className="sdnvLinkItem">
                    <Link to="/doctor-schedule" onClick={props.hideMenu} className={location.pathname === "/doctor-schedule" ? "sdnvLink activeLink" : "sdnvLink"}>
                        <img src="/images/icons/create-schedule-outline.png" className="sdnvLinkImg invertGray" alt="icon" />
                        <div className="sdnvLinkTxt"><span>{l.book_apptmnt}</span></div>
                    </Link>
                </li> */}
                {/* <li className="sdnvLinkItem">
                    <Link to="/patients-list" onClick={props.hideMenu} className={location.pathname === "/patients-list" ? "sdnvLink activeLink" : "sdnvLink"} >
                        <img src="/images/icons/patients-list.png" className="sdnvLinkImg invertGray" alt="icon" />
                        <div className="sdnvLinkTxt">
                            <span>{l.pat_list}</span>
                        </div>
                    </Link>
                </li> */}
                 <li className="sdnvLinkItem">
                    <Link to="/clinic-users" onClick={props.hideMenu} className={location.pathname === "/clinic-users" ? "sdnvLink activeLink" : "sdnvLink"}>
                        <img src="/images/icons/patients-list.png" className="sdnvLinkImg invertGray" alt="icon" />
                        <div className="sdnvLinkTxt"><span>{l.users}</span></div>
                    </Link>
                </li>
                <li className="sdnvLinkItem">
                    <Link to="/appointments" onClick={props.hideMenu} className={location.pathname === "/appointments" ? "sdnvLink activeLink" : "sdnvLink"} >
                        <img src="/images/icons/appointments-outline.png" className="sdnvLinkImg invertGray" alt="icon" />
                        <div className="sdnvLinkTxt">
                            <span>{l.apptmnts}</span>
                        </div>
                    </Link>
                </li>
                {/* <li className="sdnvLinkItem">
                    <Link to="/clinic-config" onClick={props.hideMenu} className={location.pathname === "/clinic-config" ? "sdnvLink activeLink" : "sdnvLink"}>
                        <img src="/images/icons/payment-config.png" className="sdnvLinkImg invertGray" alt="icon" />
                        <div className="sdnvLinkTxt"><span>{l.configrtn}</span></div>
                    </Link>
                </li> */}
                <li className="sdnvLinkItem">
                    <Link to="/edit-profile" onClick={props.hideMenu} className={location.pathname === "/edit-profile" ? "sdnvLink activeLink" : "sdnvLink"} >
                        <img src="/images/icons/profile-outline.png" className="sdnvLinkImg invertGray" alt="icon" />
                        <div className="sdnvLinkTxt">
                            <span>{l.my_profile}</span>
                        </div>
                    </Link>
                </li>
                <li className="sdnvLinkItem">
                    <Link to="/clinic-settings" onClick={props.hideMenu} className={location.pathname === "/clinic-settings" ? "sdnvLink activeLink" : "sdnvLink"} >
                        <img src="/images/icons/settings-outline.png" className="sdnvLinkImg invertGray" alt="icon" />
                        <div className="sdnvLinkTxt">
                            <span>{l.settings}</span>
                        </div>
                    </Link>
                </li>

            </ul>



            <ul className="sdnvLinkGroup  btmGrp">
                <li className="sdnvLinkItem">
                    <div onClick={props.handleLogout} className={location.pathname === "/" ? "sdnvLink activeLink" : "sdnvLink"}>
                        <img src="/images/icons/logout-outline.png" className="sdnvLinkImg invertGray" alt="icon" />
                        <div className="sdnvLinkTxt"><span>{l.logout}</span></div>
                    </div>
                </li>
            </ul>
        </>
    )
}
export default ClinicSideNavLinks;