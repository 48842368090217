import React from "react";
import "../../../ui-styles/pageCenter/pageCenter.css";
import Button from "../../../lib/ui/buttons/Button";


const PageCenterMsg = (props) => {
    const {fullSize} = props;
    const {size, background} = props;
    const cls = `pageCntBlock ${fullSize} ${background}`
    const clsimg = `pcIconBox ${size}`;
    
    return (
        <div className="layout404">
            <div className={cls}>
                <div className="pageCntPopup">
                    <div className={clsimg}>
                        {/* <img src="/images/icons/doc-advice.png" alt="icon" /> */}
                        <img src={props.src} alt="icon" />
                    </div>
                    <span className="pageCntHdng">{props.Heading}</span>
                    <span className="txtMd">{props.label}</span>
                    {props.btnAction &&
                    <div className="pageAction">
                        <Button
                            label={props.btnlabel}
                            type='primary'
                            size='md'
                            onClick={props.handleButton}
                        />
                    </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default PageCenterMsg;

// fullSize="flexOne"