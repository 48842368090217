import React, { useContext } from 'react';
import { LangCtx } from "../../../lib/context";
import { Link, useLocation } from 'react-router-dom';

import "../Styles.css";

const DocWebSideNavLinks = (props) => {
    const location = useLocation();
    const { l } = useContext(LangCtx);

    return (
        <>
        <ul className="wsnGroup">
            <li className="wsnItem">
                <Link to="/doctor-appointments" className={location.pathname === "/doctor-appointments" ? "wsnLink wsnAcitve" : "wsnLink"} >
                    <img src="/images/icons/appointments-outline.png" className="linkIcon invertGray" alt="icon" />
                    <div className="linkTxtBlock">
                        <span>{l.apptmnts}</span>
                    </div>
                    {/* <div className="linkCounter">
                        <span>15</span>
                    </div> */}
                </Link>
            </li>
            <li className="wsnItem">
                <Link to="/doctor-schedules" className={location.pathname === "/doctor-schedules" ? "wsnLink wsnAcitve" : "wsnLink"} >
                    <img src="/images/icons/create-schedule-outline.png" className="linkIcon invertGray" alt="icon" />
                    <div className="linkTxtBlock">
                        <span>{l.create_sched}</span>
                    </div>
                </Link>
            </li>
            <li className="wsnItem">
                <Link to="/doctor-uploads" className={location.pathname === "/doctor-uploads" ? "wsnLink wsnAcitve" : "wsnLink"} >
                    <img src="/images/icons/uploads-outline.png" className="linkIcon invertGray" alt="icon" />
                    <div className="linkTxtBlock">
                        <span>{l.waiting_uploads}</span>
                    </div>
                </Link>
            </li>
            <li className="wsnItem">
                <Link to="/doctor-invites" className={location.pathname === "/doctor-invites" ? "wsnLink wsnAcitve" : "wsnLink"} >
                    <img src="/images/icons/invite-outline.png" className="linkIcon invertGray" alt="icon" />
                    <div className="linkTxtBlock">
                        <span>{l.invite}</span>
                    </div>
                </Link>
            </li>
            <li className="wsnItem">
                <Link to="/doctor-profile" className={location.pathname === "/doctor-profile" ? "wsnLink wsnAcitve" : "wsnLink"} >
                    <img src="/images/icons/profile-outline.png" className="linkIcon invertGray" alt="icon" />
                    <div className="linkTxtBlock">
                        <span>{l.my_profile}</span>
                    </div>
                </Link>
            </li>
            <li className="wsnItem">
                <Link to="/doctor-settings" className={location.pathname === "/doctor-settings" ? "wsnLink wsnAcitve" : "wsnLink"} >
                    <img src="/images/icons/settings-outline.png" className="linkIcon invertGray" alt="icon" />
                    <div className="linkTxtBlock">
                        <span>{l.settings}</span>
                    </div>
                </Link>
            </li>
        </ul>
        <ul className="wsnGroup btmGrp">
            <li className="wsnItem">
                <Link onClick={props.handleLogout} className={location.pathname === "/" ? "wsnLink wsnAcitve" : "wsnLink"} >
                    <img src="/images/icons/logout-outline.png" className="linkIcon invertGray" alt="icon" />
                    <div className="linkTxtBlock">
                        <span>{l.logout}</span>
                    </div>
                </Link>
            </li>
        </ul>
        </>
    )
}

export default DocWebSideNavLinks;