import React, { Fragment } from 'react';
import "./Styles.css";

const Loader = (props) => {
    return <Fragment>
        <div className="loaderContainer">
            <div className="loader">
                <div className="box"></div>
                <div className="box"></div>
                <div className="box"></div>
                <div className="box"></div>
            </div>
        </div>
    </Fragment>
}

export default Loader;