import React, { useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { LangCtx } from "../../../lib/context";

const DocSideNavLinks = (props) => {
    const location = useLocation();
    const { l } = useContext(LangCtx);

    return (
        <>
        <ul className="sdnvLinkGroup">
            <li className="sdnvLinkItem">
                <Link to="/doctor-appointments" onClick={props.hideMenu} className={location.pathname === "/doctor-appointments" ? "sdnvLink activeLink" : "sdnvLink"}>
                    <img src="/images/icons/appointments-outline.png" className="sdnvLinkImg invertGray" alt="icon" />
                    <div className="sdnvLinkTxt"><span>{l.apptmnts}</span></div>
                    {/* <div className="sdnvCounter">
                        <small>12</small>
                    </div> */}
                </Link>
            </li>
            <li className="sdnvLinkItem">
                <Link to="/doctor-schedules" onClick={props.hideMenu} className={location.pathname === "/doctor-schedules" ? "sdnvLink activeLink" : "sdnvLink"}>
                    <img src="/images/icons/create-schedule-outline.png" className="sdnvLinkImg invertGray" alt="icon" />
                    <div className="sdnvLinkTxt"><span>{l.create_sched}</span></div>
                </Link>
            </li>
            <li className="sdnvLinkItem">
                <Link to="/doctor-uploads" onClick={props.hideMenu} className={location.pathname === "/doctor-uploads" ? "sdnvLink activeLink" : "sdnvLink"}>
                    <img src="/images/icons/uploads-outline.png" className="sdnvLinkImg invertGray" alt="icon" />
                    <div className="sdnvLinkTxt"><span>{l.waiting_uploads}</span></div>
                </Link>
            </li>
            <li className="sdnvLinkItem">
                <Link to="/doctor-invites" onClick={props.hideMenu} className={location.pathname === "/doctor-invites" ? "sdnvLink activeLink" : "sdnvLink"}>
                    <img src="/images/icons/invite-outline.png" className="sdnvLinkImg invertGray" alt="icon" />
                    <div className="sdnvLinkTxt"><span>{l.invite}</span></div>
                </Link>
            </li>
            <li className="sdnvLinkItem">
                <Link to="/doctor-profile" onClick={props.hideMenu} className={location.pathname === "/doctor-profile" ? "sdnvLink activeLink" : "sdnvLink"}>
                    <img src="/images/icons/profile-outline.png" className="sdnvLinkImg invertGray" alt="icon" />
                    <div className="sdnvLinkTxt"><span>{l.my_profile}</span></div>
                </Link>
            </li>
            <li className="sdnvLinkItem">
                <Link to="/doctor-settings" onClick={props.hideMenu} className={location.pathname === "/doctor-settings" ? "sdnvLink activeLink" : "sdnvLink"}>
                    <img src="/images/icons/settings-outline.png" className="sdnvLinkImg invertGray" alt="icon" />
                    <div className="sdnvLinkTxt"><span>{l.settings}</span></div>
                </Link>
            </li>
        </ul>
        <ul className="sdnvLinkGroup btmGrp">
            <li className="sdnvLinkItem">
                <Link onClick={props.handleLogout} className={location.pathname === "/" ? "sdnvLink activeLink" : "sdnvLink"}>
                    <img src="/images/icons/logout-outline.png" className="sdnvLinkImg invertGray" alt="icon" />
                    <div className="sdnvLinkTxt"><span>{l.logout}</span></div>
                </Link>
            </li>
        </ul>
        </>
    )
}
export default DocSideNavLinks;