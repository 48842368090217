import React, {useContext} from "react";
import PageCenterMsg from "../../lib/ui/pagecenter/pageCenterMsg";
import {LangCtx } from "../../lib/context";

const SessionExpired = (props) => {
    const { l } = useContext(LangCtx);

    const handleRefresh = () => {
        window.location.reload();
    }

    return <PageCenterMsg src="/images/icons/session-expired.png" Heading={l.session_hdng} label={l.session_desc} btnAction={true} btnlabel="Refresh" handleButton={handleRefresh} />
}
export default SessionExpired;
