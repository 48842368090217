import React, {useContext} from "react";
import { Route, Redirect } from "react-router-dom";
import Home from "../layouts/home/index";
import {PayLoadCtx, RoleCtx} from "../lib/context";

const UnAuthRoute = ({ component: C, props: cProps, ...rest }) => {
    const {payload} = useContext(PayLoadCtx);
    const {role} = useContext(RoleCtx);
    return <Route
        {...rest}
        render= {props => <Home {...props} {...cProps} component={(props) => <C  {...cProps} {...props} />} />}
        />
}

export default UnAuthRoute;