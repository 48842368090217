import React from 'react';
import { langObj } from "../locale/index";
import en from "../locale/en.json";
import config from "../config.json";

let _l = localStorage.getItem('lang') || config.defaultLang;
let l_data = langObj[_l] ? langObj[_l] : en;
export const LangCtx = React.createContext(l_data);

export const PayLoadCtx = React.createContext(null);

export const RoleCtx = React.createContext(null);

export const FileCtx = React.createContext(null);

export const SignalerCtx = React.createContext(null)

export const SpecializationsCtx = React.createContext(null);

export const InstavcCtx = React.createContext(null);

export const DivisionCtx = React.createContext(null);

export const DistrictCtx = React.createContext(null);

export const QualificationCtx = React.createContext(null);

export const GroupChatCtx = React.createContext(null);


export const CustomCtx = React.createContext({ logo: config.logo, whiteLable: false, subDomain: "", name: "InClinix", clientstripekey: config.clientstripekey, serverstripekey: config.serverstripekey });