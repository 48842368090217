import React, {Suspense} from "react";
import { Route } from "react-router-dom";
import "../innerLayout.css";

import Loading from "../../components/loading";

const HomeLayout = (props) => {

    return <div className="App-div">
        <Suspense fallback={<Loading />}>
            <Route exact path={props.path} component={props.component} {...props} />
        </Suspense>
    </div>
}

export default HomeLayout;
