import React, { useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { LangCtx } from "../../../lib/context";

const AdminSideNavLinks = (props) => {
    const location = useLocation();
    const { l } = useContext(LangCtx);

    return (
        <>
            <ul className="sdnvLinkGroup">
                <li className="sdnvLinkItem">
                    <Link to="/dashboard" onClick={props.hideMenu} className={location.pathname === "/dashboard" ? "sdnvLink activeLink" : "sdnvLink"}>
                        <img src="/images/icons/dashboard.png" className="sdnvLinkImg invertGray" alt="icon" />
                        <div className="sdnvLinkTxt"><span>{l.dashboard}</span></div>
                    </Link>
                </li>
                <li className="sdnvLinkItem">
                    <Link to="/admin-createusers" onClick={props.hideMenu} className={location.pathname === "/admin-createusers" ? "sdnvLink activeLink" : "sdnvLink"}>
                        <img src="/images/icons/create-patient.png" className="sdnvLinkImg invertGray" alt="icon" />
                        <div className="sdnvLinkTxt"><span>{l.create_user}</span></div>
                    </Link>
                </li>
                <li className="sdnvLinkItem">
                    <Link to="/admin-users" onClick={props.hideMenu} className={location.pathname === "/admin-users" ? "sdnvLink activeLink" : "sdnvLink"}>
                        <img src="/images/icons/patients-list.png" className="sdnvLinkImg invertGray" alt="icon" />
                        <div className="sdnvLinkTxt"><span>{l.users}</span></div>
                    </Link>
                </li>
                {/* <li className="sdnvLinkItem">
                    <Link to="/admin-advice" onClick={props.hideMenu} className={location.pathname === "/admin-advice" ? "sdnvLink activeLink" : "sdnvLink"}>
                        <img src="/images/icons/settings-outline.png" className="sdnvLinkImg invertGray" alt="icon" />
                        <div className="sdnvLinkTxt"><span>Advice</span></div>
                    </Link>
                </li> */}
                <li className="sdnvLinkItem">
                    <Link to="/admin-reports" onClick={props.hideMenu} className={location.pathname === "/admin-reports" ? "sdnvLink activeLink" : "sdnvLink"}>
                        <img src="/images/icons/analytics.png" className="sdnvLinkImg invertGray" alt="icon" />
                        <div className="sdnvLinkTxt"><span>{l.reports}</span></div>
                    </Link>
                </li>
                <li className="sdnvLinkItem">
                    <Link to="/licenses" onClick={props.hideMenu} className={location.pathname === "/licenses" ? "sdnvLink activeLink" : "sdnvLink"}>
                        <img src="/images/icons/licenses.png" className="sdnvLinkImg invertGray" alt="icon" />
                        <div className="sdnvLinkTxt"><span>{l.licenses}</span></div>
                    </Link>
                </li>
                {/* <li className="sdnvLinkItem">
                    <Link to="/admin-config" onClick={props.hideMenu} className={location.pathname === "/admin-config" ? "sdnvLink activeLink" : "sdnvLink"}>
                        <img src="/images/icons/analytics.png" className="sdnvLinkImg invertGray" alt="icon" />
                        <div className="sdnvLinkTxt"><span>Configuration</span></div>
                    </Link>
                </li> */}
                <li className="sdnvLinkItem">
                    <Link to="/admin-settings" onClick={props.hideMenu} className={location.pathname === "/admin-settings" ? "sdnvLink activeLink" : "sdnvLink"} >
                        <img src="/images/icons/settings-outline.png" className="sdnvLinkImg invertGray" alt="icon" />
                        <div className="sdnvLinkTxt">
                            <span>{l.settings}</span>
                        </div>
                    </Link>
                </li>

            </ul>
            <ul className="sdnvLinkGroup  btmGrp">
                <li className="sdnvLinkItem">
                    <Link onClick={props.handleLogout} className={location.pathname === "/" ? "sdnvLink activeLink" : "sdnvLink"}>
                        <img src="/images/icons/logout-outline.png" className="sdnvLinkImg invertGray" alt="icon" />
                        <div className="sdnvLinkTxt"><span>{l.logout}</span></div>
                    </Link>
                </li>
            </ul>
        </>
    )
}
export default AdminSideNavLinks;