import { API } from "aws-amplify";
import config from '../config.json';
import { OffLineAPI } from '../offline-auth/api';
import axios from "axios";

var _API = config.isOffline ? OffLineAPI : API;

export function getUsers(name, searchKey, token, limit,license=null) {
    let query = `consultRole=${name}`;
    query += token && token !== null && token !== '' ? `&token=${JSON.stringify(token)}` : '';
    query += searchKey && searchKey !== null && searchKey !== '' ? searchKey : '';
    query += license ? `&license=${license}` : ``
    query += limit ? `&limit=${limit}` : `&limit=10`
   
    return _API.get("users", `/users?${query}`);
}
export function getClinics() {
    let query = `consultRole=clinic`;
    //  query += limit ? `&limit=${limit}` : `&limit=10`
    return _API.get("users", `/userslist?${query}`);
}
export function getUserDetails(id) {
    return _API.get("users", `/userdetails?userId=${id}`);
}

export function updatProfilePic(body) {
    return _API.post("users", `/profilepic`, { body })
}
export function createPatient(body) {
    return _API.post("users", `/createpatient`, { body })
}

export function updateProfile(body) {
    return _API.put("users", `/updateconsultuser`, { body })
}
export function deletePatient(body) {
    return _API.post("users", `/deleteuser`, { body })
}

export function adminDashboard() {
    return _API.get("admin", `/admindashboard`)
}
export function adminUserReport() {
    return _API.get("admin", `/adminuserreport`)
}
export function clinicUserReport() {
    return _API.get("admin", `/clinicuserreport`)
}
export function adminAppointmentReport() {
    return _API.get("admin", `/adminappointmentreport`)
}

export function clinicAppointmentReport() {
    return _API.get("admin", `/clinicappointmentreport`)
}
export function createUser(body) {
    if (config.isOffline === true) {
        return _API.post("admin", `/usersignup`, { body })
    } else {
        return _API.post("admin", `/adminuser`, { body })
    }
}

export function adminDoctorReport(division, city, from, to, token, limit) {
    let query = `division=${division}&city=${city}&from=${from}&to=${to}`;
    query += token && token !== '' && token !== null ? `&token=${JSON.stringify(token)}` : '';
    query += limit ? `&limit=${limit}` : `&limit=10`
    return _API.get("admin", `/admindoctorreport?${query}`)
}

export function adminAppointmentReports(role, from, to, token, limit) {
    let query = `role=${role}&from=${from}&to=${to}`;
    query += token && token !== '' && token !== null ? `&token=${JSON.stringify(token)}` : '';
    query += limit ? `&limit=${limit}` : `&limit=10`
    return _API.get("admin", `/adminappointmentreportbyrole?${query}`)
}

export function adminAppointmentReportsExport(role, from, to, email) {
    let query = `role=${role}&from=${from}&to=${to}&email=${email}`;
    return _API.get("admin", `/exportadminappointmentreport?${query}`)
}

export function updateUser(id, body) {
    return _API.put("admin", `/updateuser/${id}`, { body })
}

export function deleteUser(body) {
    return _API.post("admin", `/deleteuser`, { body })
}
export function getDoctorUploadFiles() {
    return _API.get("users", `/doctoruploadlist`)
}
export function getDoctorFiles(id) {
    return _API.get("users", `/doctoruploadlist?userId=${id}`)
}

export function deletewaitingroomupload(body) {
    return _API.post("users", `/deletewaitingroomupload`, { body })
}

export function patientsignup(body) {
    return _API.post("users", `/patientsignup`, { body })
}

export function getDivisionList() {
    return _API.get("users", `/divisionslist`);
}
export function getDistrictList() {
    return _API.get("users", `/districtlist`);
}

export function createroom(body) {
    // let body = {
    // roomName: 'testfordemo',
    // roomOptions: {
    //     inputLimit : 10,
    //     participantLimit : 10,
    // },
    // roomType: 'C',
    // userEmail: 'venkatesh.t@peoplelinkvc.com',
    // expireAt: 1639035099641

    // }
    return _API.post("admin", `/createroom`, { body })
}
export function login(body) {
    return _API.post("users", `/login`, { body });
}

export function currentsession() {
    return _API.get("users", `/currentsession`);
}

export function currentuserinfo(userId = null) {
    let path = config.isOffline ? `/currentuserinfo` : `/currentuserinfo?sub=${userId}`;
    // return _API.get("users", `/currentuserinfo?sub=${userId}`);
    return _API.get("users", path);
}

export function getallClinics(qr = null) {
    let query = `consultRole=clinic`;
    if (qr !== null) {
        query += `&${qr}`;
    }
    //  query += limit ? `&limit=${limit}` : `&limit=10`
    return _API.get("users", `/listallclinics?${query}`);
}

export function changepasword(body) {
    return _API.put("users", `/changepassword`, { body })
}

export function verifyuser(body) {
    return _API.post("admin", `/verifyuser`, { body })
}
export function forgotPassword(body) {
    return _API.post("users", `/sendotp`, { body })
}
export function forgotPasswordSubmit(body) {
    return _API.post("users", `/validateotp`, { body })
}

export function deletefiles(body) {
    return _API.post("users", `/deletefile`, { body })
}

export function getAllAdmins() {
    let query = `consultRole=clinicAdmin`;
    //  query += limit ? `&limit=${limit}` : `&limit=10`
    return _API.get("users", `/listallclinics?${query}`);
}

export function getDoctorList(name, filterDate, from, fromTime, to, days, token, limit = 10) {
    let query = filterDate ? `consultRole=${name}&filterDate=${filterDate}` : `consultRole=${name}`;
    query += `&limit=${limit}&from=${from}&fromTime=${fromTime}&to=${to}&days=${days}`;
    query += token && token !== null && token !== '' ? `&token=${JSON.stringify(token)}` : '';
    return _API.get("users", `/users?${query}`);
}


export function checkDomain(username, domain) {
    // console.log("domain ==== ", domain);
    if (domain && domain.subDomains && domain.subDomains[0] && domain.hostname !== "localhost") {
        let body = { username, domain: domain.subDomains[0] };
        return _API.post("users", `/domainconfirmation`, { body });
    } else {
        return { status: true }
    }
}

export function doctorAvailabilityStatus(){
    return new Promise(function (resolve, reject) {
        // let url;
        let url =  config.socket.consult.API_URL+ '/connection-status';
        const headers = {
            'Content-Type': 'application/json',
        }

        axios.get(url, { headers })
            .then(res => {
                // console.log("res === ", res);
                resolve(res.data)
            }).catch(err => {
                reject(err);
            })
    })
}


export function deleteProfilePic(body) {
    return _API.post("users", '/deleteprofile', { body })
}
export function getDomainDetials(domain) {
    return _API.get("admin", `/listdomain?subdomain=${domain}`)
}

export function getStipeDetials(userId) {
    return _API.get("admin", `/liststripe/${userId}`)
}

export function updateStripeDetails(body) {
    return _API.post("admin", `/stripecreate`, { body })
}

export function getPoolDetials(userId) {
    return _API.get("admin", `/listpool/${userId}`)
}

export function updatePoolDetails(body) {
    return _API.post("admin", `/poolcreate`, { body })
}

export function updateDomainDetails(domain, body) {
    return _API.post("admin", `/stripe/${domain}`, { body })
}
export function deleteStripeDetails(subdomain) {
    return _API.del("admin", `/deletestripe?subdomain=${subdomain}`, {})
}

export function getDepartmentList() {
    return _API.get("users", `/departmentlist`);
}
export function getSpecializationList() {
    return _API.get("users", `/specializationlist`);
}

export function deleteLogo(body) {
    return _API.del("admin", `/deletelogo`, {body})
}

export function getDomainData(subdomain) {
    return new Promise((resolve, reject) => {
        fetch(`${config.usersGateway.URL}/domainlist/${subdomain}`)
            .then(res => res.json())
            .then(
            (result) => {
                resolve(result);
            },
            (error) => {
                console.log("error in domain api === ", error)
                resolve(null);
            }
            )
    });
}

export function validateSignup(body) {
    return _API.post("users", `/validatesignup`, { body })
}

export function getQualificationList() {
    return _API.get("users", `/qualificationlist`);
}

export function adminResetPassword(body) {
    return _API.put("admin", `/resetpassword`, {body});
}

export function createLicense(body) {
    return _API.post("admin", `/upsertlicense`, { body });
}

export function delLicense(body) {
    return _API.post("admin", `/dellicense`, { body });
}