import React, { Fragment, useContext } from 'react'
import './Styles.css';
import { LangCtx, PayLoadCtx, CustomCtx } from "../../lib/context";
import { langObj } from "../../locale/index";
import DropDown from "../../lib/ui/dropdown/Dropdown";
import config from "../../config.json";

const Header = (props) => {
    const { l, setLang } = useContext(LangCtx);
    const { payload } = useContext(PayLoadCtx);
    const { custom } = useContext(CustomCtx);
    // console.log("payload in headers======", payload);

    const handleLang = (e) => {
        setLang(langObj[e.target.value]);
        localStorage.setItem('lang', e.target.value);
    }
    const langOptions = [{ value: "en", label: l.english }, { value: "hi", label: l.hindi }]
    let name = payload?.consultRole && payload.consultRole === "doctor" ? "Dr. " : "";
    name += payload?.firstName ? payload.firstName : "";
    name += payload?.lastName ? " " + payload.lastName : "";
    return <Fragment>
        <div className="appBar">
            <div className="appIconBox" onClick={props.handleSlide}>
                <span></span>
                <span></span>
                <span></span>
            </div>
            <div className="brandingBox">
                {/* <div className="logoBox">
                    <img src={custom.logo} alt="logo" />
                </div> */}
                <div className="logoBox">
                    <img src="/images/logos/ayush-white-logo.png" className="invertWhite" alt="logo" />
                </div>
                <div className="indLogoBox">
                    <img src="/images/logos/national-ayush.png" className="lgLogo" alt="logo" />
                </div>
                <div className="indLogoBox">
                    <img src="/images/logos/gouLogo.png" className="lgLogo" alt="logo" />
                </div>
                <div className="indLogoBox">
                    <img src="/images/logos/ayush-society.png" className="lgLogo
                    " alt="logo" />
                </div>

            </div>
            <div className="innerLangBlock">
                <DropDown placeholder="Languages" items={langOptions} value={localStorage.getItem('lang') ? localStorage.getItem('lang') : l.locale || 'en'} onChange={handleLang} />
            </div>
            <div className="webAppBarAction">
                {/* <div className='flexAutoCol'>
                    <span className="wlcomTxt whiteTxt">{l.welcome} </span>
                    <small className="primaryTxt whiteTxt">{name}</small>
                </div> */}
                <div className="flexAutoCol prfName pdngHXSM">
                    <span className="wlcomText">{l.welcome}</span>
                    <small className="primaryTxt whiteTxt ellipsisTxt txtAlignEnd">{name}</small>
                </div>
                <div className="avtrCircle">
                    <img src={payload?.profileImage?.url && payload.profileImage.url !== config.defaultProfileImage ? payload.profileImage.url : "/images/icons/profile-outline.png"} className={payload?.profileImage?.url && payload.profileImage.url !== config.defaultProfileImage ? "" : "invertWhite"} alt="logo" />
                </div>
            </div>

        </div>
    </Fragment>
}
export default Header;

