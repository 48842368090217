import React, { useContext} from "react";
import PageCenterMsg from "../../lib/ui/pagecenter/pageCenterMsg";
import { LangCtx } from "../../lib/context";

const SessionExpired = (props) => {
    const { l } = useContext(LangCtx);

    const handleRefresh = () => {
        window.location.reload();
    }

    return <PageCenterMsg src="/images/icons/no-internet.png" Heading={l.no_internet} label={l.no_internet_desc} btnlabel={l.try_agn} handleButton={handleRefresh} btnAction="true" />
}
export default SessionExpired;
