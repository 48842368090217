import React from "react";
import { Route } from "react-router-dom";
import ConferenceLayout from "../layouts/conference/index";
const ConferenceRoute = ({ component: C, props: cProps, ...rest }) => {
    // const { payload } = useContext(PayLoadCtx);
    // const { role } = useContext(RoleCtx);
    return <Route
        {...rest}
        // render={props => payload && (role === "C" || role === "D" || role === "P") ? <ConferenceLayout {...props} {...cProps} role="Admin" component={(props) => <C  {...cProps} {...props} />} /> : <Redirect to="/" />} />
        render={props =>

            <ConferenceLayout {...props} {...cProps} component={(props) => <C  {...cProps} {...props} />} />
        } /> 
        /*:
        sessionStorage.reload ? 
        <Redirect
            to={{
                pathname: "/test"
            }
            }
        />*/
}

export default ConferenceRoute;