import React, { useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import { LangCtx } from "../../../lib/context";

const PatientSideNavLinks = (props) => {
    const location = useLocation();
    const { l } = useContext(LangCtx);

    return (
        <>
            <ul className="sdnvLinkGroup">
                <li className="sdnvLinkItem">
                    <Link to="/patient-dashboard" onClick={props.hideMenu} className={location.pathname === "/patient-dashboard" ? "sdnvLink activeLink" : "sdnvLink"}>
                        <img src="/images/icons/appointments-outline.png" className="sdnvLinkImg invertGray" alt="icon" />
                        <div className="sdnvLinkTxt"><span>{l.apptmnts}</span></div>
                    </Link>
                </li>
                <li className="sdnvLinkItem">
                    <Link to="/doctor-booking" onClick={props.hideMenu} className={location.pathname === "/doctor-booking" ? "sdnvLink activeLink" : "sdnvLink"}>
                        <img src="/images/icons/create-schedule-outline.png" className="sdnvLinkImg invertGray" alt="icon" />
                        <div className="sdnvLinkTxt"><span>{l.book_apptmnt}</span></div>
                    </Link>
                </li>
                <li className="sdnvLinkItem">
                    <Link to="/patient-uploads" onClick={props.hideMenu} className={location.pathname === "/patient-uploads" ? "sdnvLink activeLink" : "sdnvLink"}>
                        <img src="/images/icons/uploads-outline.png" className="sdnvLinkImg invertGray" alt="icon" />
                        <div className="sdnvLinkTxt"><span>{l.my_uploads}</span></div>
                    </Link>
                </li>
                <li className="sdnvLinkItem">
                    <Link to="/patient-profile" onClick={props.hideMenu} className={location.pathname === "/patient-profile" ? "sdnvLink activeLink" : "sdnvLink"}>
                        <img src="/images/icons/profile-outline.png" className="sdnvLinkImg invertGray" alt="icon" />
                        <div className="sdnvLinkTxt"><span>{l.my_profile}</span></div>
                    </Link>
                </li>
                <li className="sdnvLinkItem">
                    <Link to="/patient-settings" onClick={props.hideMenu} className={location.pathname === "/patient-settings" ? "sdnvLink activeLink" : "sdnvLink"}>
                        <img src="/images/icons/settings-outline.png" className="sdnvLinkImg invertGray" alt="icon" />
                        <div className="sdnvLinkTxt"><span>{l.settings}</span></div>
                    </Link>
                </li>
            </ul>
            <ul className="sdnvLinkGroup btmGrp">
                <li className="sdnvLinkItem">
                    <Link onClick={props.handleLogout} className={location.pathname === "/" ? "sdnvLink activeLink" : "sdnvLink"}>
                        <img src="/images/icons/logout-outline.png" className="sdnvLinkImg invertGray" alt="icon" />
                        <div className="sdnvLinkTxt"><span>{l.logout}</span></div>
                    </Link>
                </li>
            </ul>
        </>
    );
};
export default PatientSideNavLinks;
