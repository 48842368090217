import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import Amplify from "aws-amplify";
import Storage from "@aws-amplify/storage";
import config from "./config.json";


// Amplify.configure({
//   Auth: {
//     mandatorySignIn: true,
//     region: config.cognito.REGION,
//     userPoolId: config.cognito.USER_POOL_ID,
//     identityPoolId: config.cognito.IDENTITY_POOL_ID,
//     userPoolWebClientId: config.cognito.APP_CLIENT_ID
//   },
//   Storage: {
//     region: config.s3.REGION,
//     bucket: config.s3.BUCKET,
//     identityPoolId: config.cognito.IDENTITY_POOL_ID
// },
//   API: {
//     endpoints: [
//       {
//         name: "schedule",
//         endpoint: config.schGateway.URL,
//         region: config.schGateway.REGION
//       },
//       {
//         name: "users",
//         endpoint: config.usersGateway.URL,
//         region: config.usersGateway.REGION
//       },
//       {
//         name: "appointment",
//         endpoint: config.appointGateway.URL,
//         region: config.appointGateway.REGION 
//       },
//       {
//         name: "admin",
//         endpoint: config.adminGateway.URL,
//         region: config.adminGateway.REGION 
//       },

//       {
//         name: "mcu",
//         endpoint: config.mcuGateway.URL,
//         region: config.mcuGateway.REGION
//       }
//     ]
//   }
// })

export function SetS3Config(bucket, level){
  Storage.configure({ 
         bucket: bucket,
         level: level,
         region: 'ap-southeast-1',
         identityPoolId: config.cognito.IDENTITY_POOL_ID 
      });
}



ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
