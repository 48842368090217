import React, { Fragment } from 'react';
import './Styles.css';

import UserInfo from './components/UserInfo';
import DocSideNavLinks from './components/DocSideNavLinks';
import ClinicSideNavLinks from "./components/ClinicSiveNavLinks";
import PatientSideNavLinks from "./components/PatientSideNavLinks";
import AdminSideNavLinks from "./components/AdminSideNavLinks";


const Sidenav = (props) => {

    const loadNav = () => {
        switch(props.role) {
            case "Doctor": return <DocSideNavLinks hideMenu={props.handleSlide} handleLogout={props.handleLogout}/>;
            case "Clinic": return <ClinicSideNavLinks hideMenu={props.handleSlide} handleLogout={props.handleLogout} />;
            case "Admin": return <AdminSideNavLinks hideMenu={props.handleSlide} handleLogout={props.handleLogout} />;
            default: return <PatientSideNavLinks hideMenu={props.handleSlide} handleLogout={props.handleLogout} />;;
        }
    }

    return <Fragment>
        <nav className={props.slide ? 'sideNav slideIn' : 'sideNav'}>
            <div className="sideNavBlock">
                <div className="sideNavInfo">
                    <div className="sdnvHdr" onClick={props.handleSlide}>
                        <div className="sdnvClose"></div>
                    </div>
                    <UserInfo />
                </div>
                <div className="sideNavLinkGroup">
                    {loadNav()}
                </div>

            </div>
        </nav>
        <div className={props.slide ? "slideFade isVisible" : 'slideFade'}></div>
    </Fragment>
}
export default Sidenav;