import config from "../config.json";
import DispatcherEvent from "./DispatcherEvent";
// import { Auth } from 'aws-amplify';
import { toast } from 'react-toastify';
import io from "socket.io-client";

export default class ConsultSignaler {
    constructor() {
        // property definitions
        this.events = {};
        this.wsUri = config.socket.consult.URL;
        // this.wsUri = "wss://8a7v2yzkva.execute-api.ap-southeast-1.amazonaws.com/dev";
        this.socket = null;
        this.isConnected = false;
        this.subscribers = [];
        this.reConnect = false;
        this.uid = null;
        this.isBusy = false;
        this.resendwr = true;
        this.resendCall = true;
    }

    dispatch(eventName, data) {
        const event = this.events[eventName];
        if (event) {
            event.fire(data);
        }
    }

    on(eventName, callback) {
        let event = this.events[eventName];
        if (!event) {
            event = new DispatcherEvent(eventName);
            this.events[eventName] = event;
        }
        event.registerCallback(callback);
    }

    off(eventName, callback) {
        const event = this.events[eventName];
        if (event && event.callbacks.indexOf(callback) > -1) {
            event.unregisterCallback(callback);
            if (event.callbacks.length === 0) {
                delete this.events[eventName];
            }
        }
    }

    onOpen = (event) => {
        console.log("Websocket Open");
        this.isConnected = true;
        this.dispatch('connected', event);

        // if(this.reConnect && this.uid) {
        //     this.subscribers.forEach((subscriber) => {
        //         this.subscribeOnlineStatus(subscriber, this.uid);
        //     });
        //     this.reConnect = false;
        // }
    }

    onClose = (event) => {
        console.log("Websocket Closed", event, this);
        this.isConnected = false;
        // // this.uid = null;
        // this.dispatch('off-subscribe', event.data);
        // this.dispatch('closed-ws', event.data);
        if(config.isOffline){
            if (localStorage.getItem('token')) {
                this.init(this.uid, this.role, true);
            } else {
                if (window.location.pathname !== "/") {
                    this.dispatch('disconnected');
                }
                this.socket = null;
                this.uid = null;
                this.isConnected = false;
            }
        } else {
            if (this.socket) {
                this.init(this.uid, this.role, true);
            } else {
                if (window.location.pathname !== "/") {
                    this.dispatch('disconnected');
                }
                this.socket = null;
                this.uid = null;
                this.isConnected = false;
            }
            // Auth.currentSession().then(mySession => {
                
            // }).catch(err => {
            //     console.log("err ===== ", err);
                
            // })
        }
    }

    onError = (event) => {
        console.log("Websocket Error", event);
        if (window.location.pathname !== "/") {
            this.dispatch('disconnected');
        }
        this.socket = null;
        this.uid = null;
        this.isConnected = false;
        // localStorage.removeItem('token');
    }

    init = function (uid, role, reConnect = false) {
        // console.log('uid to ', uid, role);
        try{
            if (this.socket === null) {
                if(config.isOffline) {
                    
                    let token = localStorage.getItem('token');
                    let clientId = new Date().valueOf();
                    if (localStorage.getItem('clientId')) {
                        clientId = localStorage.getItem('clientId');
                    } else {
                        localStorage.setItem('clientId', clientId);
                    }
                    this.socket = io(this.wsUri, {
                        query: {
                            userId: uid,
                            role: role,
                            token: token,
                            clientId: clientId
                        },
                        // transports: ["websockets", "polling"]
                    });
                } else {
                    this.socket = io(`${this.wsUri}?userId=${uid}&role=${role}`);

                }
                console.log("this.socket==========", this.socket);
                // this.socket.onopen = this.onOpen;
                // this.socket.onclose = this.onClose;
                // this.socket.onmessage = this.onMessage;
                // this.socket.onerror = this.onError;
                this.socket.on("connect", this.onOpen);
                this.socket.on("disconnect", this.onClose);
                this.socket.on("error", this.onError);
                this.socket.on("ENTERWR", (datastr) => {
                    console.log("data in wrstatus ==== ", datastr);
                    let data = JSON.parse(datastr);
                    this.dispatch(`wrstatus${data.apptId}`, data.senderId);
                });
                this.socket.on("EXITWR", (datastr) => {
                    let data = JSON.parse(datastr);
                    // console.log("#####is this the one").
                    this.dispatch(`wrstatus${data.apptId}`, null);
                });
                this.socket.on("CALLREQ", (data) => {
                    
                    this.dispatch('recive-call', data);
                    this.resendCall = true;
                });

                this.socket.on("DECLINEREQ", (datastr) => {
                    let data = JSON.parse(datastr);
                    this.dispatch('decline-request');
                    let p_name = data.firstName ? data.firstName : "Patient";
                    p_name += data.lastName ? " " + data.lastName : "";
                    toast.success(`${p_name} declined the call request`, {
                        position: toast.POSITION.TOP_RIGHT
                    })
                });

                this.socket.on("DECLINEREQ_ACK", (datastr) => {
                    let data = JSON.parse(datastr);
                    this.dispatch('acerdec-call-ack');
                });
                this.socket.on("CANCELREQ", (data) => {
                    let d_name = data.firstName ? data.firstName : "Doctor";
                    d_name += data.lastName ? " " + data.lastName : "";
                    toast.success(`Dr. ${d_name} cancelled the call request`, {
                        position: toast.POSITION.TOP_RIGHT
                    })
                    this.resendCall = true;
                    this.dispatch('cancel-call');
                });
                this.socket.on("CANCELREQ_ACK", (datastr) => {
                    let data = JSON.parse(datastr);
                    this.dispatch('cancel-call-ack');
                });
                this.socket.on("ACCEPTREQ", (data) => {
                    this.dispatch('accept-call');
                    this.resendCall = true;
                });
                this.socket.on("ACCEPTREQ_ACK", (datastr) => {
                    let data = JSON.parse(datastr);
                    this.dispatch('acerdec-call-ack');
                });
                this.socket.on("PENDLIST", (data) => {
                    this.dispatch('reload-pending-list');
                });
                this.socket.on("APPTSTATUS", (data) => {
                    this.dispatch(`reload-appt-list`);
                });

                this.socket.on("online", (data) => {
                    if (data && data.id) {
                        this.dispatch(`online-${data.id}`);
                    }
                });
                this.socket.on("offline", (data) => {
                    if (data && data.id) {
                        this.dispatch(`offline-${data.id}`);
                    }
                });
                window.webSock = this.socket;

                this.reConnect = reConnect;
                this.uid = uid;
                this.role = role;
            } else {
                console.log("connection exists");
            }
        } catch(e) {
            console.log("error in signelar init ==== ", e);
            this.init(uid, role, false);
        }
    }

    closeSocket = () => {
        if (this.socket) {
            this.socket.close();
            this.socket = null;
            this.uid = null;
            this.isConnected = false;
        }
    }

    enterWaitingRoom = (apptId, userId) => {
        console.log("this.isConnected === ", this.isConnected);
        if (this.isConnected && this.socket) {
            this.resendwr = false;
            // let message = {
            //     action: 'onMessage',
            //     message: 'ENTERWR',
            //     uid: userId,
            //     data: {
            //         senderId: this.uid,
            //         apptId: apptId

            //     }
            // }
            // // console.log("message ==== ", message);
            // this.socket.send(JSON.stringify(message));
            const message = {
                senderId: this.uid,
                apptId: apptId,
                receiverId: userId,
            };
            this.socket.emit("ENTERWR", message);
            this.dispatch('enterwr-sent');
        } else if (this.socket && this.resendwr) {
            setTimeout(() => {
                this.enterWaitingRoom(apptId, userId);
            },300);
        } 
        // else {
        //     this.dispatch('disconnected');
        // }
    }

    exitWaitingRoom = (apptId, userId) => {
        this.resendwr = true;
        if (this.isConnected && this.socket) {
            
            // let message = {
            //     action: 'onMessage',
            //     message: 'EXITWR',
            //     uid: userId,
            //     data: {
            //         senderId: this.uid,
            //         apptId: apptId

            //     }
            // }
            // // console.log("message ==== ", message);
            // this.socket.send(JSON.stringify(message));
            const message = {
                senderId: this.uid,
                apptId: apptId,
                receiverId: userId,
            };
            this.socket.emit("EXITWR", message);
            this.dispatch("exitwr-sent");
        } 
    }

    sendCallRequest = (doctorData, userId) => {
       
        if (this.isConnected && this.socket) {
            this.resendCall = false;
            // let message = {
            //     action: 'onMessage',
            //     message: 'CALLREQ',
            //     uid: userId,
            //     data: doctorData
            // }
            // this.socket.send(JSON.stringify(message));
            doctorData["senderId"] = this.uid;
            doctorData["patientId"] = userId;
            this.socket.emit("CALLREQ", {
                data: doctorData,
            });
            this.dispatch("callreq-sent");
        } else if (this.socket && this.resendCall) {
            setTimeout(() => {
                this.sendCallRequest(doctorData, userId);
            }, 300);
        }
        
    }

    declineCallRequest = (userId, patientData) => {
        if (this.isConnected && this.socket) {
            this.resendCall = false;
            // let message = {
            //     action: 'onMessage',
            //     message: 'DECLINEREQ',
            //     uid: userId,
            //     data: patientData
            // }
            // this.socket.send(JSON.stringify(message));
            if (this.uid && patientData) {
                patientData["senderId"] = this.uid;
                patientData["receiverId"] = userId;
            }
            this.socket.emit("DECLINEREQ", {
                data: patientData,
            });
            this.dispatch('declinereq-sent');
        } else if (this.socket && this.resendCall) {
            setTimeout(() => {
                this.declineCallRequest(userId);
            }, 300);
        } 
        // else {
        //     this.dispatch('disconnected');
        // }
    }

    cancelCallRequest = (userId, doctorData) => {
        if (this.isConnected && this.socket) {
            this.resendCall = true;
            // let message = {
            //     action: 'onMessage',
            //     message: 'CANCELREQ',
            //     uid: userId,
            //     data: doctorData
            // }
            // this.socket.send(JSON.stringify(message));
            doctorData['patientId'] = userId;
            this.socket.emit("CANCELREQ", {
                data: doctorData
            });
            this.dispatch("cancelreq-sent");
        } else if (this.socket && !this.resendCall) {
            setTimeout(() => {
                this.cancelCallRequest(userId);
            }, 300);
        }
        // else {
        //     this.dispatch('disconnected');
        // }
    }

    acceptCallRequest = (userId) => {
        // console.log("userId ==== ", userId);
        if (this.isConnected && this.socket) {
            this.resendCall = false;
            // let message = {
            //     action: 'onMessage',
            //     message: 'ACCEPTREQ',
            //     uid: userId,
            //     data: null
            // }
            // this.socket.send(JSON.stringify(message));
            this.socket.emit("ACCEPTREQ", {
                data: { senderId: this.uid, receiverId: userId },
            });
            this.dispatch("acceptreq-sent");
        } else if (this.socket && this.resendCall) {
            setTimeout(() => {
                this.acceptCallRequest(userId);
            }, 300);
        }
    }

    renderAppoints = (userId, msg, data = null) => {
        if (this.isConnected) {
            // let message = {
            //     action: 'onMessage',
            //     message: msg,
            //     uid: userId,
            //     data: data
            // }
            // this.socket.send(JSON.stringify(message));
            this.socket.emit(msg, {
                uid: userId,
                data: data,
            });
        } 
        // else {
        //     this.dispatch('disconnected');
        // }
    }
}
