import React from 'react';

import "./Styles.css";
import WebDocSideNavLinks from "./components/WebDocSideNavLinks";
import WebClinicSideNavLinks from "./components/WebClinicSideNavLinks";
import WebPatientSideNavLinks from "./components/WebPatientSideNavLinks";
import WebAdminSideNavLinks from "./components/WebAdminSideNaveLinks";

const WebSideNav = (props) => {
    const loadNav = () => {
        switch(props.role) {
            case "Doctor": return <WebDocSideNavLinks handleLogout={props.handleLogout} />;
            case "Clinic": return <WebClinicSideNavLinks handleLogout={props.handleLogout} />;
            case "Admin": return <WebAdminSideNavLinks handleLogout={props.handleLogout} />;
            default: return <WebPatientSideNavLinks handleLogout={props.handleLogout} />;
        }
    }
    return (
        <div className="webSideNav">
            <div className="wsnBlock">
                {loadNav()}
           </div>
        </div>
    )
}

export default WebSideNav;