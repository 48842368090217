import React, { Suspense, Fragment, useState, useContext } from "react";
import { Route, useHistory } from "react-router-dom";
import Header from '../../components/header/index';
import Sidenav from '../../components/sidenav/index';
import WebSideNav from "../../components/sidenav/webSideNav";
import Loading from "../../components/loading";
// import { Auth } from "../../offline-auth/auth";
import {PayLoadCtx, RoleCtx} from "../../lib/context";
import { Auth } from "aws-amplify";
import config from "../../config.json";

const AuthLayout = (props) => {
    const [sideNav, setSideNav] = useState(false);
    const history = useHistory();
    const {setRole} = useContext(RoleCtx);
    const {getPayLoad} = useContext(PayLoadCtx)
    const handleSideNav = () => {
        setSideNav(prevState => !prevState);
    }
    
    const handleLogout = async (e) => {
        e.preventDefault();
        // setLoading(true);
        if (config.isOffline) {
            localStorage.removeItem('sub');
            localStorage.removeItem('token');
            getPayLoad();
        } else {
            await Auth.signOut();
        }
        setRole(null)
        history.push("/");
    }

    return <Fragment>
        <Header handleSlide={handleSideNav} role={props.role}/>
        <Sidenav handleSlide={handleSideNav} slide={sideNav} handleLogout={handleLogout} role={props.role}/>
        <WebSideNav handleLogout={handleLogout} role={props.role}/>
        <div className="innerMainContainer">
            <Suspense fallback={<Loading />}>
                <Route exact path={props.path} component={props.component} {...props} />
            </Suspense>
        </div>
    </Fragment>
}

export default AuthLayout;
