import React, {useContext} from 'react';
import {LangCtx} from "../../context";
// For CSS
import './Dropdown.css';
import hi from "../../../locale/hi.json" 


const DropDown = (props) => {
    const {l} = useContext(LangCtx);
    return (
        <div className={`selectInput ${props.size}`}>
            <select onChange={props.onChange} value={props.value} name={props.name}>
                {!props.RFC && props.items && props.items.length > 0 && props.items.map((item) => <option value={item.value} key={item.value}>{item.label ? item.label : item.defaultlabel ? l[item.defaultlabel] : l[item.value]}</option>)}

                {props.RFC && l.locale==="en" && props.items && props.items.length > 0 && props.items.map((item) => <option value={item.value} key={item.value}>{item.label ? item.label : item.defaultlabel ? l[item.defaultlabel] : `${l[item.value]} (${hi[item.value]})`}</option>)}

            </select>
            <label>{props.labelText}</label>
            {props.error && <span className="selectErrorMsg">{props.errorMsg}</span>}
        </div>
    )
}
export default DropDown;

