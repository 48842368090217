import React, {useContext} from "react";
import { Route, Redirect } from "react-router-dom";
import AuthLayout from "../layouts/auth/index";
import {PayLoadCtx, RoleCtx} from "../lib/context";
const AdminRoute = ({ component: C, props: cProps, ...rest }) => {
    const {payload} = useContext(PayLoadCtx);
    const {role} = useContext(RoleCtx);
    return <Route
        {...rest}
        render={props => payload && (role === 'CA' || role === 'SA') ? <AuthLayout {...props} {...cProps} role="Admin" component={(props) => <C  {...cProps} {...props} />} /> : <Redirect to="/" />} />
}

export default AdminRoute;