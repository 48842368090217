import React, { lazy } from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";

// Routes
import PublicRoute from "./routes/public";
import DoctorRoute from "./routes/doctor";
import PatientRoute from "./routes/patient";
import ClinicRoute from "./routes/clinic";
import AdminRoute from "./routes/admin";
import ConferenceRoute from "./routes/conferenceRoute";
import UnAuthRoute from "./routes/unauth";

import CallbackRoute from "./routes/callback";

// Page Components
const AsyncLogin = lazy(() => import("./containers/login/index"));
//const AsyncLogin = lazy(() => import("./containers/login/moeenlogin"));

//Authentication Pages
const AsyncSignUp = lazy(() => import("./containers/signup/index"));
const AsyncForgotPassword = lazy(() => import("./containers/forgotPassword/index"));
const AsyncResetPassword = lazy(() => import("./containers/resetPassword/index"));
const AsyncVerifyAccount = lazy(() => import("./containers/verifyAccount/index"));
const AsyncTermsnConditions = lazy(() => import("./containers/common/TermsnConditions"));
const AsyncPrivacyPolicy = lazy(() => import("./containers/common/PrivacyPolicy"));
//ADMIN-INCLINIX
const AsyncAdminSignUp = lazy(() => import("./containers/signup/admin-signup"));
const AsyncConfirmMsg = lazy(() => import("./containers/signup/ConfirmMsgPopup"));

//DOCTOR
const AsyncDoctorSchedules = lazy(() => import("./containers/doctor/doctorSchedules/index"));
const AsyncDoctorAppointments = lazy(() => import("./containers/doctor/doctorAppointments/index"));
const AsyncDoctorUploads = lazy(() => import("./containers/common/fileUploads/index"));
const AsyncDoctorProfile = lazy(() => import("./containers/doctor/doctorProfile/index"));
const AsyncDoctorInvites = lazy(() => import("./containers/doctor/doctorInvites/index"));
const AsyncDoctorSettings = lazy(() => import("./containers/doctor/doctorSettings/index"));

//CLINIC
// const AsyncClinicDashboard = lazy(() => import("./containers/clinic/index"));
// PATIENT
const AsyncPatientSettings = lazy(() => import("./containers/patient/patientSettings/index"));
const AsyncPatientProfile = lazy(() => import("./containers/patient/patientProfile/index"));
const AsyncPatientUploads = lazy(() => import("./containers/common/fileUploads/index"));
const AsyncPatientAppointments = lazy(() => import("./containers/common/appointments/index"));
const AsyncWaitingRoom = lazy(() => import("./containers/common/waitingroom/index"));
const AsyncPatientFailure = lazy(() => import("./containers/common/bookAppointment/components/PaymentFailure"));

// CLINIC
const AsyncClinicDashboard = lazy(() => import("./containers/clinic/dashboard/index"));
const AsyncCreatePatient = lazy(() => import("./containers/clinic/createPatient/index"));
const AsyncDoctorSchedule = lazy(() => import("./containers/common/doctorSchedule/index"));
const AsyncBookAppointment = lazy(() => import("./containers/common/bookAppointment/index"));
const AsyncPatientsList = lazy(() => import("./containers/clinic/patientslist/index"));
const AsyncClinicAppointments = lazy(() => import("./containers/common/appointments/index"));
const AsyncClinicProfile = lazy(() => import("./containers/clinic/profile/index"));
const AsyncClinicSettings = lazy(() => import("./containers/clinic/clinicSettings/index"));
const AsyncClinicConfig = lazy(() => import("./containers/clinic/clinicConfiguration/index"));

// ADMIN
const AsyncAdminDashboard = lazy(() => import("./containers/admin/dashboard"));
const AsyncAdminCreateUsers = lazy(() => import("./containers/admin/createUsers/index"));
const AsyncAdminUsers = lazy(() => import("./containers/admin/users/index"));
const AsyncAdminAdvice = lazy(() => import("./containers/admin/advice/index"));
const AsyncAdminReports = lazy(() => import("./containers/admin/reports/index"));
const AsyncAdminSettings = lazy(() => import("./containers/admin/adminSettings/index"));
const AsyncAdminLicenses = lazy(() => import("./containers/admin/licenses/index"));

// CONFERENCE
const AsyncConferenceLayout = lazy(() => import("./containers/conference/index"));
const AsyncConfSettings = lazy(() => import("./containers/conference/confSettings/index"));
const AsyncTestSettings = lazy(() => import("./containers/conference/components/test"));
const AsyncGuestConfSettings = lazy(() => import("./containers/conference/confSettings/guestindex"));
const AsyncConfLayout = lazy(() => import("./containers/conference/confLayout"));
const AsyncGuestConfLayout = lazy(() => import("./containers/conference/guestConfLayout"));
const AsyncConferenceAccess = lazy(() => import("./containers/conference/ConferenceAccess"));
const AsyncViewPrescription = lazy(() => import("./containers/searchPrescription/OpenPrescription"));
const AsyncSearchPrescription = lazy(() => import("./containers/searchPrescription/index"));

const AsyncTabBg = lazy(() => import("./containers/tabbg/index"));

class Routes extends React.Component {
    // constructor(props) {
    //     super(props);
    // }

    componentWillMount() {
        //  sessionStorage.reload = true;
    }

    render() {
        // if (sessionStorage.reload  ) {
        //     console.log(this.props);
        //     sessionStorage.reload = "";
        //     window.location.href = "/";
        //    // history.push('/');
        // }
       
        return (
            <Router>
                <Switch>
                    <PublicRoute path="/" exact component={AsyncLogin} {...this.props} />
                    <PublicRoute path="/sign-up" exact component={AsyncSignUp} {...this.props} />
                    <PublicRoute path="/admin-sign-up" exact component={AsyncAdminSignUp} {...this.props} />
                    <PublicRoute path="/confirm-msg" exact component={AsyncConfirmMsg} {...this.props} />

                    <PublicRoute path="/forgot-password" exact component={AsyncForgotPassword} {...this.props} />
                    <PublicRoute path="/reset-password" exact component={AsyncResetPassword} {...this.props} />
                    <PublicRoute path="/verify-account" exact component={AsyncVerifyAccount} {...this.props} />
                    <PublicRoute path="/search-prescription" exact component={AsyncSearchPrescription} {...this.props} />
                    <PublicRoute path="/tabbg" exact component={AsyncTabBg} {...this.props} />
                    <UnAuthRoute path="/terms-conditions" exact component={AsyncTermsnConditions} {...this.props} />
                    <UnAuthRoute path="/privacy-policy" exact component={AsyncPrivacyPolicy} {...this.props} />

                    {/* AFTER LOGIN  */}
                    <DoctorRoute path="/doctor-schedules" exact component={AsyncDoctorSchedules} {...this.props} />
                    <DoctorRoute path="/doctor-appointments" exact component={AsyncDoctorAppointments} {...this.props} />
                    <DoctorRoute path="/doctor-uploads" exact component={AsyncDoctorUploads} {...this.props} />
                    <DoctorRoute path="/doctor-profile" exact component={AsyncDoctorProfile} {...this.props} />
                    <DoctorRoute path="/doctor-invites" exact component={AsyncDoctorInvites} {...this.props} />
                    <DoctorRoute path="/doctor-settings" exact component={AsyncDoctorSettings} {...this.props} />
                    <PatientRoute path="/patient-dashboard" exact component={AsyncPatientAppointments} {...this.props} />
                    <PatientRoute path="/doctor-booking" exact component={AsyncDoctorSchedule} {...this.props} />
                    <PatientRoute path="/book-appointment/:dId" exact component={AsyncBookAppointment} {...this.props} />
                    <PatientRoute path="/patient-settings" exact component={AsyncPatientSettings} {...this.props} />
                    <PatientRoute path="/patient-profile" exact component={AsyncPatientProfile} {...this.props} />
                    <PatientRoute path="/patient-uploads" exact component={AsyncPatientUploads} {...this.props} />
                    <PatientRoute path="/payment-failure/" exact component={AsyncPatientFailure} {...this.props} />

                    <ClinicRoute path="/clinic-dashboard" exact component={AsyncClinicDashboard} {...this.props} />
                    <ClinicRoute path="/create-patient" exact component={AsyncCreatePatient} {...this.props} />
                    <ClinicRoute path="/create-patient/:pId" exact component={AsyncCreatePatient} {...this.props} />
                    <ClinicRoute path="/clinic-users" exact component={AsyncAdminUsers} {...this.props} />
                    <ClinicRoute path="/doctor-schedule/:pId" exact component={AsyncDoctorSchedule} {...this.props} />
                    <ClinicRoute path="/clinic/book-appointment/:dId/:pId" exact component={AsyncBookAppointment} {...this.props} />
                    <ClinicRoute path="/patients-list" exact component={AsyncPatientsList} {...this.props} />
                    <ClinicRoute path="/appointments" exact component={AsyncClinicAppointments} {...this.props} />
                    <ClinicRoute path="/edit-profile" exact component={AsyncClinicProfile} {...this.props} />
                    <ClinicRoute path="/clinic-settings" exact component={AsyncClinicSettings} {...this.props} />
                    <ClinicRoute path="/clinic-createusers" exact component={AsyncAdminCreateUsers} {...this.props} />
                    <ClinicRoute path="/clinic-config" exact component={AsyncClinicConfig} {...this.props} />
                    {/* <ClinicRoute
                path="/patient-waiting-room/:id"
                exact
                component={AsyncWaitingRoom}
                {...this.props}
            /> */}

                    <AdminRoute path="/dashboard" exact component={AsyncAdminDashboard} {...this.props} />
                    <AdminRoute path="/admin-dashboard" exact component={AsyncAdminDashboard} {...this.props} />
                    <AdminRoute path="/admin-createusers" exact component={AsyncAdminCreateUsers} {...this.props} />
                    <AdminRoute path="/admin-users" exact component={AsyncAdminUsers} {...this.props} />
                    <AdminRoute path="/admin-users/:profile" exact component={AsyncAdminUsers} {...this.props} />
                    <AdminRoute path="/admin-advice" exact component={AsyncAdminAdvice} {...this.props} />
                    <AdminRoute path="/admin-reports" exact component={AsyncAdminReports} {...this.props} />
                    <AdminRoute path="/admin-settings" exact component={AsyncAdminSettings} {...this.props} />
                    <AdminRoute path="/licenses" exact component={AsyncAdminLicenses} {...this.props} />
                    <ConferenceRoute path="/conference/:id/:audioOff/:videoOff" exact component={AsyncConfLayout} {...this.props} />
                    <ConferenceRoute path="/doctor-conference/:id/:uid/:audioOff/:videoOff" exact component={AsyncConfLayout} {...this.props} />
                    <ConferenceRoute path="/guest-settings/:id" exact component={AsyncGuestConfSettings} {...this.props} />
                    <ConferenceRoute path="/conference-settings/:id" exact component={AsyncConfSettings} {...this.props} />
                    <ConferenceRoute path="/doc-conf-settings/:id/:uid" exact component={AsyncConfSettings} {...this.props} />
                    <ConferenceRoute path="/waiting-room/:id/:audioOff/:videoOff" exact component={AsyncWaitingRoom} {...this.props} />
                    <ConferenceRoute path="/test" exact component={AsyncTestSettings} {...this.props} />
                    <ConferenceRoute path="/guest-conference/:id" exact component={AsyncGuestConfLayout} {...this.props} />
                    <CallbackRoute path="/get-prescription/:id" exact component={AsyncViewPrescription} {...this.props} />
                    <CallbackRoute path="/conference-access/:token/:sub" exact component={AsyncConferenceAccess} {...this.props} />
                </Switch>
            </Router>
        );
    }
}

export default Routes;
