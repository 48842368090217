import React, { useContext } from 'react';
import { LangCtx } from "../../../lib/context";
import { useLocation, Link } from 'react-router-dom';

import "../Styles.css";

const WebAdminSideNavLinks = (props) => {
    const location = useLocation();
    const { l } = useContext(LangCtx);

    return (
        <>
        <ul className="wsnGroup">
            <li className="wsnItem">
                    <Link to="/dashboard" onClick={props.hideMenu} className={location.pathname === "/dashboard" ? "wsnLink wsnAcitve" : "wsnLink"}>
                        <img src="/images/icons/dashboard.png" className="linkIcon invertGray" alt="icon" />
                        <div className="linkTxtBlock"><span>{l.dashboard}</span></div>
                    </Link>
                </li>
                <li className="wsnItem">
                    <Link to="/admin-createusers" onClick={props.hideMenu} className={location.pathname === "/admin-createusers" ? "wsnLink wsnAcitve" : "wsnLink"}>
                        <img src="/images/icons/create-patient.png" className="linkIcon invertGray" alt="icon" />
                        <div className="linkTxtBlock"><span>{l.create_user}</span></div>
                    </Link>
                </li>
                <li className="wsnItem">
                    <Link to="/admin-users" onClick={props.hideMenu} className={location.pathname === "/admin-users" ? "wsnLink wsnAcitve" : "wsnLink"}>
                        <img src="/images/icons/patients-list.png" className="linkIcon invertGray" alt="icon" />
                        <div className="linkTxtBlock"><span>{l.users}</span></div>
                    </Link>
                </li>
                {/* <li className="wsnItem">
                    <Link to="/admin-advice" onClick={props.hideMenu} className={location.pathname === "/admin-advice" ? "wsnLink wsnAcitve" : "wsnLink"}>
                        <img src="/images/icons/settings-outline.png" className="linkIcon invertGray" alt="icon" />
                        <div className="linkTxtBlock"><span>Advice</span></div>
                    </Link>
                </li> */}
                <li className="wsnItem">
                    <Link to="/admin-reports" onClick={props.hideMenu} className={location.pathname === "/admin-reports" ? "wsnLink wsnAcitve" : "wsnLink"}>
                        <img src="/images/icons/analytics.png" className="linkIcon invertGray" alt="icon" />
                        <div className="linkTxtBlock"><span>{l.reports}</span></div>
                    </Link>
                </li>
                <li className="wsnItem">
                    <Link to="/licenses" onClick={props.hideMenu} className={location.pathname === "/licenses" ? "wsnLink wsnAcitve" : "wsnLink"}>
                        <img src="/images/icons/licenses.png" className="linkIcon invertGray" alt="icon" />
                        <div className="linkTxtBlock"><span>{l.licenses}</span></div>
                    </Link>
                </li>
                {/* <li className="wsnItem">
                    <Link to="/admin-config" onClick={props.hideMenu} className={location.pathname === "/admin-config" ? "wsnLink wsnAcitve" : "wsnLink"}>
                        <img src="/images/icons/analytics.png" className="linkIcon invertGray" alt="icon" />
                        <div className="linkTxtBlock"><span>Configuration</span></div>
                    </Link>
                </li> */}
                <li className="wsnItem">
                    <Link to="/admin-settings" onClick={props.hideMenu} className={location.pathname === "/admin-settings" ? "wsnLink wsnAcitve" : "wsnLink"}>
                        <img src="/images/icons/settings-outline.png" className="linkIcon invertGray" alt="icon" />
                        <div className="linkTxtBlock"><span>{l.settings}</span></div>
                    </Link>
                </li>

        </ul>
        <ul className="wsnGroup btmGrp">
            <li className="wsnItem">
                <Link onClick={props.handleLogout} className={location.pathname === "/" ? "wsnLink wsnAcitve" : "wsnLink"} >
                    <img src="/images/icons/logout-outline.png" className="linkIcon invertGray" alt="icon" />
                    <div className="linkTxtBlock">
                        <span>{l.logout}</span>
                    </div> 
                </Link>
            </li>
        </ul>
        </>
    )
}

export default WebAdminSideNavLinks;