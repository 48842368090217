import React, {useContext} from "react";
import { Route, Redirect } from "react-router-dom";
import AuthLayout from "../layouts/auth/index";
import {PayLoadCtx, RoleCtx} from "../lib/context";
const PatientRoute = ({ component: C, props: cProps, ...rest }) => {
    const {payload} = useContext(PayLoadCtx);
    const {role} = useContext(RoleCtx);
    return <Route
        {...rest}
        render={props => payload && role === "P" ? <AuthLayout {...props} {...cProps} role="Patient" component={(props) => <C  {...cProps} {...props} />} /> : <Redirect to="/" />} />
}

export default PatientRoute;