import React from 'react';
import './BtnStyles.css';

const Button = (props) => {
    const {size, type, fullWidth, label, buttonType, growOne, onClick} = props;
    const cls = `btn ${size} ${type} ${fullWidth} ${growOne}`;
    //const icon = `img ${white} ${primary}`
    return <button className={cls} onClick={onClick} type={buttonType ? buttonType : 'button'} disabled={props.disabled}>
            {label}
        </button>
}

export default Button;

//size: lg,md,sm
//type, primary,outLine,link    