import React, { useContext } from "react";
import { LangCtx } from "../../../lib/context";
import { Link, useLocation } from "react-router-dom";

import "../Styles.css";

const WebPatientSideNavLinks = (props) => {
    const location = useLocation();
    const { l } = useContext(LangCtx);

    return (
        <>
            <ul className="wsnGroup">
                <li className="wsnItem">
                    <Link to="/patient-dashboard" className={location.pathname === "/patient-dashboard" ? "wsnLink wsnAcitve" : "wsnLink"} >
                        <img src="/images/icons/appointments-outline.png" className="linkIcon invertGray" alt="icon" />
                        <div className="linkTxtBlock">
                            <span>{l.apptmnts}</span>
                        </div>
                    </Link>
                </li>
                <li className="wsnItem">
                    <Link to="/doctor-booking" className={location.pathname === "/doctor-booking" ? "wsnLink wsnAcitve" : "wsnLink"}>
                        <img src="/images/icons/create-schedule-outline.png" className="linkIcon invertGray" alt="icon" />
                        <div className="linkTxtBlock">
                            <span>{l.book_apptmnt}</span>
                        </div>
                    </Link>
                </li>
                <li className="wsnItem">
                    <Link to="/patient-uploads" className={location.pathname === "/patient-uploads" ? "wsnLink wsnAcitve" : "wsnLink"}>
                        <img src="/images/icons/uploads-outline.png" className="linkIcon invertGray" alt="icon" />
                        <div className="linkTxtBlock">
                            <span>{l.my_uploads}</span>
                        </div>
                    </Link>
                </li>
                <li className="wsnItem">
                    <Link to="/patient-profile" className={location.pathname === "/patient-profile" ? "wsnLink wsnAcitve" : "wsnLink"}>
                        <img src="/images/icons/profile-outline.png" className="linkIcon invertGray" alt="icon" />
                        <div className="linkTxtBlock">
                            <span>{l.my_profile}</span>
                        </div>
                    </Link>
                </li>
                <li className="wsnItem">
                    <Link to="/patient-settings" className={location.pathname === "/patient-settings" ? "wsnLink wsnAcitve" : "wsnLink"}>
                        <img src="/images/icons/settings-outline.png" className="linkIcon invertGray" alt="icon" />
                        <div className="linkTxtBlock">
                            <span>{l.settings}</span>
                        </div>
                    </Link>
                </li>
            </ul>
            <ul className="wsnGroup btmGrp">
                <li className="wsnItem">
                    <Link onClick={props.handleLogout} className={location.pathname === "/" ? "wsnLink wsnAcitve" : "wsnLink"}>
                        <img src="/images/icons/logout-outline.png" className="linkIcon invertGray" alt="icon" />
                        <div className="linkTxtBlock">
                            <span>{l.logout}</span>
                        </div>
                    </Link>
                </li>
            </ul>
        </>
    );
};

export default WebPatientSideNavLinks;
