import React, { useContext } from 'react';

import { PayLoadCtx } from "../../../lib/context";
const UserInfo = (props) => {

    const { payload } = useContext(PayLoadCtx);
    return <div className="colBlock sdnvUsrInfo">
        <div className="sdnvUsrAvtr">
            {payload && payload['custom:profilePic'] ?
                <img src={payload['custom:profilePic']} alt="logo" />
                : <span>{payload && payload.name ? payload.name.substr(0,2).toUpperCase() : ''}</span>}
        </div>
        <span className="sdnvTxtL">{payload && payload.name ? payload.name : ''}</span>
        {payload && payload.email && <small className="sdnvTxtS">{payload.email}</small>}
    </div>

}

export default UserInfo;