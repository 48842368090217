import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import Home from "../layouts/home/index";

const CallbackRoute = ({ component: C, props: cProps, ...rest }) => {
    return <Route
        {...rest}
        render={props => <Home {...props} {...cProps} component={(props) => <C  {...cProps} {...props} />} />
        } />
    }
    export default CallbackRoute;