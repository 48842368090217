import React, { useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { LangCtx } from "../../../lib/context";

import "../Styles.css";

const WebClinicSideNavLinks = (props) => {
    const location = useLocation();
    const { l } = useContext(LangCtx);

    return (
        <>
            <ul className="wsnGroup">
                {/* clinic links */}
                <li className="wsnItem">
                    <Link to="/clinic-dashboard" className={location.pathname === "/clinic-dashboard" ? "wsnLink wsnAcitve" : "wsnLink"} >
                        <img src="/images/icons/dashboard.png" className="linkIcon invertGray" alt="icon" />
                        <div className="linkTxtBlock">
                            <span>{l.dashboard}</span>
                        </div>
                    </Link>
                </li>
                <li className="wsnItem">
                    <Link to="/clinic-createusers" onClick={props.hideMenu} className={location.pathname === "/clinic-createusers" ? "wsnLink wsnAcitve" : "wsnLink"}>
                        <img src="/images/icons/create-patient.png" className="linkIcon invertGray" alt="icon" />
                        <div className="linkTxtBlock"><span>{l.create_pat}</span></div>
                    </Link>
                </li>
                {/* <li className="wsnItem">
                    <Link to="/create-patient" className={location.pathname === "/create-patient" ? "wsnLink wsnAcitve" : "wsnLink"} >
                        <img src="/images/icons/create-patient.png" className="linkIcon invertGray" alt="icon" />
                        <div className="linkTxtBlock">
                            <span>{l.cret_new_pat}</span>
                        </div>
                    </Link>
                </li> */}
                {/* <li className="wsnItem">
                    <Link to="/doctor-schedule" className={location.pathname === "/doctor-schedule" ? "wsnLink wsnAcitve" : "wsnLink"} >
                        <img src="/images/icons/create-schedule-outline.png" className="linkIcon invertGray" alt="icon" />
                        <div className="linkTxtBlock">
                            <span>{l.book_apptmnt}</span>
                        </div>
                    </Link>
                </li> */}
                {/* <li className="wsnItem">
                    <Link to="/patients-list" className={location.pathname === "/patients-list" ? "wsnLink wsnAcitve" : "wsnLink"} >
                        <img src="/images/icons/patients-list.png" className="linkIcon invertGray" alt="icon" />
                        <div className="linkTxtBlock">
                            <span>{l.pat_list}</span>
                        </div>
                    </Link>
                </li> */}
                 <li className="wsnItem">
                    <Link to="/clinic-users" onClick={props.hideMenu} className={location.pathname === "/clinic-users" ? "wsnLink wsnAcitve" : "wsnLink"}>
                        <img src="/images/icons/patients-list.png" className="linkIcon invertGray" alt="icon" />
                        <div className="linkTxtBlock"><span>{l.patients}</span></div>
                    </Link>
                </li>
                <li className="wsnItem">
                    <Link to="/appointments" className={location.pathname === "/appointments" ? "wsnLink wsnAcitve" : "wsnLink"} >
                        <img src="/images/icons/appointments-outline.png" className="linkIcon invertGray" alt="icon" />
                        <div className="linkTxtBlock">
                            <span>{l.apptmnts}</span>
                        </div>
                    </Link>
                </li>
                {/* <li className="wsnItem">
                    <Link to="/clinic-config" onClick={props.hideMenu} className={location.pathname === "/clinic-config" ? "wsnLink wsnAcitve" : "wsnLink"}>
                        <img src="/images/icons/payment-config.png" className="linkIcon invertGray" alt="icon" />
                        <div className="linkTxtBlock"><span>{l.configrtn}</span></div>
                    </Link>
                </li> */}
                <li className="wsnItem">
                    <Link to="/edit-profile" className={location.pathname === "/edit-profile" ? "wsnLink wsnAcitve" : "wsnLink"} >
                        <img src="/images/icons/profile-outline.png" className="linkIcon invertGray" alt="icon" />
                        <div className="linkTxtBlock">
                            <span>{l.my_profile}</span>
                        </div>
                    </Link>
                </li>
                <li className="wsnItem">
                    <Link to="/clinic-settings" className={location.pathname === "/clinic-settings" ? "wsnLink wsnAcitve" : "wsnLink"} >
                        <img src="/images/icons/settings-outline.png" className="linkIcon invertGray" alt="icon" />
                        <div className="linkTxtBlock">
                            <span>{l.settings}</span>
                        </div>
                    </Link>
                </li>
            </ul>
            <ul className="wsnGroup btmGrp">
                <li className="wsnItem">
                    <div onClick={props.handleLogout} className={location.pathname === "/" ? "wsnLink wsnAcitve" : "wsnLink"} >
                        <img src="/images/icons/logout-outline.png" className="linkIcon invertGray" alt="icon" />
                        <div className="linkTxtBlock">
                            <span>{l.logout}</span>
                        </div>
                    </div>
                </li>
            </ul>
        </>
    )
}

export default WebClinicSideNavLinks;